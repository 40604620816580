import React from 'react';
import { currencyFormat, dateFormat, showValue, minutesToDuration, timeDifference, timeFormatHhMm, getNewDateFormted } from '../../shared/commonHelper';
import * as flightHelper from '../../shared/flightHelper';
import moment from 'moment';
import { Collapse } from 'reactstrap';

class FlightSearchDetailsRightGDSRoundTripComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            sendMailPopup: '',
            sendMailAddress: null,
            sendMailValues: [],
            isShowFareDetailpopup: false,
            // limit: 10,
            offeredFare: false,
            includeFare: false,
            vendorFareRule: null,
            fareRuleSource: "",
            selectedCollapse: null,
            kafilaFareRules:null,
            containerHeight:0,
            containerStyle:{}
        };
    }

    offeredClick() {
        this.setState({ offeredFare: !this.state.offeredFare });
    }

    //Show and hide send email popup
    showMainPopup = () => {
        if (this.state.sendMailPopup === '')
            this.setState({ sendMailPopup: 'show' });
        else
            this.setState({ sendMailPopup: '' });
    }

    //Set state with information of flights to be send through email
    saveEmailData = (event) => {
        if (event.target.checked === true) {
            this.state.sendMailValues.push(event.target.value);
        }
        else {
            var index = this.state.sendMailValues.indexOf(event.target.value);
            if (index > -1) {
                this.state.sendMailValues.splice(index, 1);
            }
        }
    }

    //Send itineraries mails
    sendMail = () => {
        this.props.sendItinerariesMail(this.state.sendMailAddress, this.state.sendMailValues, this.state.includeFare);
    }

    //Show fare details
    showFareDetailsPopup = (event, resultIndex = '', isVendor = '', fareRules = '', segements = '', apiSource = '',uid='',airline_remark='',airline_code,supplier_airline_remark, flightId,trackId) => {
        let traceID = this.props.searchResult.trace_id;
        // if (resultIndex !== '' && isVendor !== 1 && apiSource !=="kafila") {
        //     this.props.flightFareDetails(traceID, resultIndex, segements, apiSource,uid);
        // }
        if (resultIndex !== '' && isVendor !== 1) {
            this.props.flightFareDetails(traceID, resultIndex, segements, apiSource,uid,airline_remark,airline_code,supplier_airline_remark, flightId,trackId);
        }
        this.setState({
            fareRuleSource: apiSource
        })
        // if(apiSource==="kafila"){
        //     this.setState({
        //         kafilaFareRules:fareRules,
        //         fareRuleSource:apiSource
        //     })
        // }
        if (isVendor === 1) {
            this.setState({
                vendorFareRule: fareRules
            });
        } else {
            this.setState({
                vendorFareRule: null
            });
        }
        this.setState({ isShowFareDetailpopup: this.state.isShowFareDetailpopup === true ? false : true })
    }

    handleResize=()=>{
        if(window.innerWidth <= 766){
            document.getElementById("cont-st") && document.getElementById("cont-st").classList.remove('page-containers-start')
            document.addEventListener('scroll', this.trackScrolling);
        }else{
            document.getElementById("cont-st") && document.getElementById("cont-st").classList.add('page-containers-start') 
            document.getElementById("cont-st") && document.getElementById("cont-st").addEventListener('scroll', this.trackScrollingM);
        }
      }

    loadMoreData = () => {
        let totalResults = this.props.searchResult && this.props.searchResult.results && flightHelper.filterResults(this.props.advanceSearchFilter[0], this.props.searchResult.results[0], this.props.resultSorting, 'oneway','multi-city');
        let totalCheck = (this.props.searchQuery && 
            (this.props.searchQuery.journey_type === "2" && this.props.searchQuery.is_domestic === 'false' &&  this.props.searchQuery.roundTripType === 'normalReturn' ) || 
            (this.props.searchQuery.journey_type === "5" && this.props.searchQuery.is_domestic === 'false' && this.props.searchQuery.roundTripType === 'LCC')  ||  
            (this.props.searchQuery.journey_type === "5" && this.props.searchQuery.is_domestic === 'false' && this.props.searchQuery.roundTripType === 'GDS')) ? 
            (totalResults && flightHelper.filterResults((this.props.advanceSearchFilter[1] ), totalResults, this.props.resultSorting, 'oneway','multi-city')) :
            totalResults
        const sameFlightResults = totalCheck && totalCheck.length > 0 && flightHelper.sameFlightFilterResultsForGDSRound(totalCheck);
        const allSameFlightKeys = sameFlightResults && Object.keys(sameFlightResults);
        const nextResultsToShow = Math.min(this.props.isFilterCheckedStatus + 25, allSameFlightKeys.length);
        this.props.isFilterChecked(nextResultsToShow)
      };
    trackScrollingM = () => {
        let totalResults = this.props.searchResult && this.props.searchResult.results && flightHelper.filterResults(this.props.advanceSearchFilter[0], this.props.searchResult.results[0], this.props.resultSorting, 'oneway','multi-city');
        let totalCheck = (this.props.searchQuery && 
            (this.props.searchQuery.journey_type === "2" && this.props.searchQuery.is_domestic === 'false' &&  this.props.searchQuery.roundTripType === 'normalReturn' ) || 
            (this.props.searchQuery.journey_type === "5" && this.props.searchQuery.is_domestic === 'false' && this.props.searchQuery.roundTripType === 'LCC')  ||  
            (this.props.searchQuery.journey_type === "5" && this.props.searchQuery.is_domestic === 'false' && this.props.searchQuery.roundTripType === 'GDS')) ? 
            (totalResults && flightHelper.filterResults((this.props.advanceSearchFilter[1] ), totalResults, this.props.resultSorting, 'oneway','multi-city')) :
            totalResults
        const sameFlightResults = totalCheck && totalCheck.length > 0 && flightHelper.sameFlightFilterResultsForGDSRound(totalCheck);
        const allSameFlightKeys = sameFlightResults && Object.keys(sameFlightResults);
        const container = document.querySelector('.page-containers-start');
        const footer = document.querySelector('.footers-end');
        if (container && footer) {
            const containerScrollRemaining =
                container.scrollHeight - container.scrollTop - container.clientHeight;
            const footerHeight = footer.scrollHeight;
            if (containerScrollRemaining - 2 < footerHeight && this.props.isFilterCheckedStatus < allSameFlightKeys.length) {
                this.loadMoreData();
            }
        }
        if (this.scrollTimeout) {
            clearTimeout(this.scrollTimeout);
        }

        this.scrollTimeout = setTimeout(() => {
            if (container && container.scrollHeight < 200) {
                this.props.scroll_position({ ...this.props.track_scroll_position,childContainer: "" });
            } else {
                this.props.scroll_position({ ...this.props.track_scroll_position,childContainer: 1 });
            }
        }, 500); 
      };
      
      trackScrolling = () => {
          let section = document.getElementsByClassName("page-container")[0];
          let footer = document.getElementsByTagName("footer")[0];
          let pageScorllRemaining = (section.scrollHeight + footer.scrollHeight - window.pageYOffset - window.innerHeight);
          if (pageScorllRemaining < footer.scrollHeight) {
              this.loadMoreData()
          }
  };

    componentDidMount() {
        let liHeight=document.getElementById('calcHeight') && document.getElementById('calcHeight').clientHeight
        let filterHEight=document.getElementById('adv-filter');
        let cliHeight=filterHEight && filterHEight.clientHeight;
        let resultToREnder=liHeight ? Math.ceil(cliHeight/liHeight)+3 : 0
        let isResultToREnder = resultToREnder && resultToREnder > 20  ? resultToREnder : 20
        let totalResults = this.props.searchResult && this.props.searchResult.results && flightHelper.filterResults(this.props.advanceSearchFilter[0] , this.props.searchResult.results[0], this.props.resultSorting, 'oneway','multi-city');
        let totalCheck = (this.props.searchQuery && 
            (this.props.searchQuery.journey_type === "2" && this.props.searchQuery.is_domestic === 'false' &&  this.props.searchQuery.roundTripType === 'normalReturn' ) || 
            (this.props.searchQuery.journey_type === "5" && this.props.searchQuery.is_domestic === 'false' && this.props.searchQuery.roundTripType === 'LCC')  ||  
            (this.props.searchQuery.journey_type === "5" && this.props.searchQuery.is_domestic === 'false' && this.props.searchQuery.roundTripType === 'GDS')) ? 
            (totalResults && flightHelper.filterResults((this.props.advanceSearchFilter[1] ), totalResults, this.props.resultSorting, 'oneway','multi-city')) :
            totalResults
        const sameFlightResults = totalCheck && totalCheck.length > 0 && flightHelper.sameFlightFilterResultsForGDSRound(totalCheck);
        const allSameFlightKeys = sameFlightResults && Object.keys(sameFlightResults);
        if(allSameFlightKeys && allSameFlightKeys.length){
            for (let initialResults = 6; initialResults <= isResultToREnder; initialResults=initialResults+2) {
                setTimeout(() => {
                  this.props.isFilterChecked(initialResults);
                }, 5 * (initialResults - 1));
              }
        }
          window.addEventListener('resize',this.handleResize)
          if(window.innerWidth <= 766){
            this.setState({containerStyle:{}})
            document.getElementById("cont-st") && document.getElementById("cont-st").classList.remove('page-containers-start')
            document.addEventListener('scroll', this.trackScrolling);
          }else{
            document.getElementById("cont-st") && document.getElementById("cont-st").classList.add('page-containers-start')
            document.getElementById("cont-st") && document.getElementById("cont-st").addEventListener('scroll', this.trackScrollingM);
          }
    }

    componentWillUnmount() {
        if(window.innerWidth <= 766){
            document.removeEventListener('scroll', this.trackScrolling);
        }else{
            document.getElementById('cont-st') && document.getElementById('cont-st').removeEventListener('scroll', this.trackScrollingM);
        }
        window.removeEventListener('resize',this.handleResize)
    }

    componentDidUpdate(prev,latest,val){
        if(document.getElementsByClassName("page-containers-start") && document.getElementsByClassName("page-containers-start").length === 0){
            if(window.innerWidth <= 766){
              }else{
                document.getElementById("cont-st") && document.getElementById("cont-st").classList.add('page-containers-start')
                document.getElementById("cont-st") && document.getElementById("cont-st").addEventListener('scroll', this.trackScrollingM);
              }
        }
    }

    //Prev day Outbound flight search
    prevDayOutboundFlightSearch = () => {
        let updatedDate = getNewDateFormted(this.props.searchQuery.segments[0].preferred_arrival_time.split('T')[0]);
        updatedDate.setDate(updatedDate.getDate() - 1);
        let prevDay = moment(updatedDate).format('YYYY-MM-DD') + 'T00:00:00';
        this.props.searchQuery.segments[0].preferred_arrival_time = prevDay;
        this.props.searchQuery.segments[0].preferred_departure_time = prevDay;
        const is_kafila_user = localStorage.getItem('is_kafila_user');
        setTimeout(() => {
			if (['1', '2', '5'].includes(this.props.searchQuery.journey_type) && this.props.searchQuery.pft === 'REGULAR')
                if (this.props.searchQuery.flightCabinClassName === 'Business' || this.props.searchQuery.flightCabinClassName === 'First' || this.props.searchQuery.flightCabinClassName === 'Premium Economy') {
                }
                else {
                    this.props.getGoflyFlightSearchResults(this.props.searchQuery);
                }
			if(this.props.searchQuery.pft === 'REGULAR')
            {
                setTimeout(() => {
                    if(['1', '2'].includes(this.props.searchQuery.journey_type))
                        {
                                this.props.getTripJackFlightSearchResults(this.props.searchQuery);
                        }
                    }, 1000);    
            }
            else if(['1', '2'].includes(this.props.searchQuery.journey_type))
            {
                this.props.getTripJackFlightSearchResults(this.props.searchQuery);
            }
		}, 1000);
        if(this.props.searchQuery.pft === 'REGULAR')
		{
		    this.props.getFlightSearchResults(this.props.searchQuery);
        }
    }

    //Next day Inbound flight search
    nextDayOutboundFlightSearch = () => {
        let updatedDate = getNewDateFormted(this.props.searchQuery.segments[0].preferred_arrival_time.split('T')[0]);
        updatedDate.setDate(updatedDate.getDate() + 1);
        let nextDay = moment(updatedDate).format('YYYY-MM-DD') + 'T00:00:00';
        this.props.searchQuery.segments[0].preferred_arrival_time = nextDay;
        this.props.searchQuery.segments[0].preferred_departure_time = nextDay;
        const is_kafila_user = localStorage.getItem('is_kafila_user');
        setTimeout(() => {
			if (['1', '2', '5'].includes(this.props.searchQuery.journey_type) && this.props.searchQuery.pft === 'REGULAR')
                if (this.props.searchQuery.flightCabinClassName === 'Business' || this.props.searchQuery.flightCabinClassName === 'First' || this.props.searchQuery.flightCabinClassName === 'Premium Economy') {
                }
                else {
                    this.props.getGoflyFlightSearchResults(this.props.searchQuery);
                }
			if(this.props.searchQuery.pft === 'REGULAR')
            {
                setTimeout(() => {
                    if(['1', '2'].includes(this.props.searchQuery.journey_type))
                        {
                                this.props.getTripJackFlightSearchResults(this.props.searchQuery);
                        }
                    }, 1000);    
            }
            else if(['1', '2'].includes(this.props.searchQuery.journey_type))
            {
                this.props.getTripJackFlightSearchResults(this.props.searchQuery);
            }
		}, 1000);
        if(this.props.searchQuery.pft === 'REGULAR')
		{
		    this.props.getFlightSearchResults(this.props.searchQuery);
        }
    }

    //Prev day Inbound flight search
    prevDayInboundFlightSearch = () => {
        let updatedDate = getNewDateFormted(this.props.searchQuery.segments[1].preferred_arrival_time.split('T')[0]);
        updatedDate.setDate(updatedDate.getDate() - 1);
        let prevDay = moment(updatedDate).format('YYYY-MM-DD') + 'T00:00:00';
        this.props.searchQuery.segments[1].preferred_arrival_time = prevDay;
        this.props.searchQuery.segments[1].preferred_departure_time = prevDay;
        const is_kafila_user = localStorage.getItem('is_kafila_user');
        setTimeout(() => {
			if (['1', '2', '5'].includes(this.props.searchQuery.journey_type) && this.props.searchQuery.pft === 'REGULAR')
                if (this.props.searchQuery.flightCabinClassName === 'Business' || this.props.searchQuery.flightCabinClassName === 'First' || this.props.searchQuery.flightCabinClassName === 'Premium Economy') {
                }
                else {
                    this.props.getGoflyFlightSearchResults(this.props.searchQuery);
                }
			if(this.props.searchQuery.pft === 'REGULAR')
            {
                setTimeout(() => {
                    if(['1', '2'].includes(this.props.searchQuery.journey_type))
                        {
                                this.props.getTripJackFlightSearchResults(this.props.searchQuery);
                        }
                    }, 1000);    
            }
            else if(['1', '2'].includes(this.props.searchQuery.journey_type))
            {
                this.props.getTripJackFlightSearchResults(this.props.searchQuery);
            }
		}, 1000);
        if(this.props.searchQuery.pft === 'REGULAR')
		{
		    this.props.getFlightSearchResults(this.props.searchQuery);
        }
    }

    //Next day Outbound flight search
    nextDayInboundFlightSearch = () => {
        let updatedDate = getNewDateFormted(this.props.searchQuery.segments[1].preferred_arrival_time.split('T')[0]);
        updatedDate.setDate(updatedDate.getDate() + 1);
        let nextDay = moment(updatedDate).format('YYYY-MM-DD') + 'T00:00:00';
        this.props.searchQuery.segments[1].preferred_arrival_time = nextDay;
        this.props.searchQuery.segments[1].preferred_departure_time = nextDay;
        const is_kafila_user = localStorage.getItem('is_kafila_user');
        setTimeout(() => {
            if (['1', '2', '5'].includes(this.props.searchQuery.journey_type) && this.props.searchQuery.pft === 'REGULAR')
                if (this.props.searchQuery.flightCabinClassName === 'Business' || this.props.searchQuery.flightCabinClassName === 'First' || this.props.searchQuery.flightCabinClassName === 'Premium Economy') {
                }
                else {
                    this.props.getGoflyFlightSearchResults(this.props.searchQuery);
                }
			if(this.props.searchQuery.pft === 'REGULAR')
            {
                setTimeout(() => {
                    if(['1', '2'].includes(this.props.searchQuery.journey_type))
                        {
                                this.props.getTripJackFlightSearchResults(this.props.searchQuery);
                        }
                    }, 1000);    
            }
            else if(['1', '2'].includes(this.props.searchQuery.journey_type))
            {
                this.props.getTripJackFlightSearchResults(this.props.searchQuery);
            }
		}, 1000);
        if(this.props.searchQuery.pft === 'REGULAR')
		{
		    this.props.getFlightSearchResults(this.props.searchQuery);
        }
    }

    setSelectedCollapse = (target) =>{
        this.setState({selectedCollapse: target})
    }

    render() {
        let flightFareRulesData = this.state.fareRuleSource === 'tripjack' ? this.props.tripJackFlightFareRuleDetails.response :
        this.state.fareRuleSource === 'kafila' ?  this.props.kafilaFlightFareRuleDetails.response :
        this.state.fareRuleSource === 'omairiq' ?  this.props.airIQFlightFareRuleDetails.response : this.state.fareRuleSource === 'gofly' ? this.props.goflyFlightFareRuleDetails.response : this.state.fareRuleSource === 'satkar' ?  this.props.satkarFlightFareRuleDetails.response : this.state.fareRuleSource === 'fbq' ?  this.props.fareBoutiqueFlightFareRuleDetails.response: this.state.fareRuleSource === 'riya_travel' ?  this.props.riyaFlightFareRuleDetails.response : this.props.flightFareRuleDetails.response;
  
        let totalResults = this.props.searchResult && this.props.searchResult.results && flightHelper.filterResults(this.props.advanceSearchFilter[0], this.props.searchResult.results[0], this.props.resultSorting, 'oneway','multi-city');
        let traceId = this.props.searchResult && this.props.searchResult.trace_id;
        let searchQuery = this.props.searchQuery;
        let self = this;
        let offeredFared = this.state.offeredFare;
        let param="";
        if(this.props.searchQuery.roundTripType === "GDS"){
            param ="";
        }
        else{
            param = this.props.searchResult.param;
        }

        let fairRuleSeatOriginDestination = '';
        let fairRules = [];
        if(this.state.fareRuleSource === 'tripjack' && flightFareRulesData && Object.keys(flightFareRulesData).length && Object.keys(flightFareRulesData)[0] !== '0')
        {
            fairRuleSeatOriginDestination = flightFareRulesData['fareRule'] ?  Object.keys(flightFareRulesData['fareRule'])  :'';
            flightFareRulesData['fareRule'] && flightFareRulesData['fareRule'][fairRuleSeatOriginDestination] && flightFareRulesData['fareRule'][fairRuleSeatOriginDestination]['tfr'] && Object.keys(flightFareRulesData['fareRule'][fairRuleSeatOriginDestination]['tfr']).length > 0 &&
            Object.keys(flightFareRulesData['fareRule'][fairRuleSeatOriginDestination]['tfr']).forEach((fareRule, index)=>{
                if (fareRule !== "SEAT_CHARGEABLE") {
                    Object.keys(flightFareRulesData["fareRule"][fairRuleSeatOriginDestination]["tfr"][fareRule]).length > 0 &&
                      Object.keys(flightFareRulesData["fareRule"][fairRuleSeatOriginDestination]["tfr"][fareRule]).forEach((item, key) => {
                        let rulesArrKey=""
                        if(flightFareRulesData["fareRule"][fairRuleSeatOriginDestination]["tfr"][fareRule][item].st !== undefined ||  flightFareRulesData["fareRule"][fairRuleSeatOriginDestination]["tfr"][fareRule][item].et !== undefined){
                            rulesArrKey = (flightFareRulesData["fareRule"][fairRuleSeatOriginDestination]["tfr"][fareRule][item].st !== '' ?
                              flightFareRulesData["fareRule"][fairRuleSeatOriginDestination]["tfr"][fareRule][item].st + " hr - " : '') +
                              (flightFareRulesData["fareRule"][fairRuleSeatOriginDestination]["tfr"][fareRule][item].et !== '' ?
                                  (flightFareRulesData["fareRule"][fairRuleSeatOriginDestination]["tfr"][fareRule][item].et !== '8760' ?
                                      flightFareRulesData["fareRule"][fairRuleSeatOriginDestination]["tfr"][fareRule][item].et + ' hr' :
                                      (flightFareRulesData["fareRule"][fairRuleSeatOriginDestination]["tfr"][fareRule][item].et / 24) + ' days') : '');
                        }else{
                            rulesArrKey=  flightFareRulesData["fareRule"][fairRuleSeatOriginDestination]["tfr"][fareRule][item].pp
                        }
                           
                        if (!Array.isArray(fairRules[rulesArrKey])) {
                          fairRules[rulesArrKey] = [];
                        }
                        if (!Array.isArray(fairRules[rulesArrKey][fareRule])) {
                          fairRules[rulesArrKey][fareRule] = [];
                        }
                        fairRules[rulesArrKey][fareRule] =flightFareRulesData["fareRule"][fairRuleSeatOriginDestination]["tfr"][fareRule][item];
                      });
                  }
            })
        }
        let totalCheck = (this.props.searchQuery && 
            (this.props.searchQuery.journey_type === "2" && this.props.searchQuery.is_domestic === 'false' &&  this.props.searchQuery.roundTripType === 'normalReturn' ) || 
            (this.props.searchQuery.journey_type === "5" && this.props.searchQuery.is_domestic === 'false' && this.props.searchQuery.roundTripType === 'LCC')  ||  
            (this.props.searchQuery.journey_type === "5" && this.props.searchQuery.is_domestic === 'false' && this.props.searchQuery.roundTripType === 'GDS')) ? 
            (totalResults && flightHelper.filterResults((this.props.advanceSearchFilter[1] ), totalResults, this.props.resultSorting, 'oneway','multi-city')) :
            totalResults
        const sameFlightResults = totalCheck && totalCheck.length > 0 && flightHelper.sameFlightFilterResultsForGDSRound(totalCheck);
        const allSameFlightKeys = sameFlightResults && Object.keys(sameFlightResults);
        const {selectedCollapse} = this.state;
        let stopData = flightHelper.getFlightStopsDirectFlightLength(totalCheck, searchQuery.direct_flight);

        return (
            <div className="right-side">
                <div className="search-right-section gds-return-section">
                    <div className="filter-btn"><span>Filter</span>
                        { /* eslint-disable-next-line */}
                        <a href="javascript:void(0);" className="btn btn-primary"><i className="icon icon-filter"></i> Filter</a>
                    </div>
                    {!totalCheck || totalCheck.length === 0 ?
                        (
                            <div className="no-result-wrap">
                                <div className="result-wrap-outer">
                                    <div className="block">
                                        <div className="no-result-box">
                                            <figure><img src="assets/images/no-result.svg" alt="" /></figure>
                                            <h2>No results found</h2>
                                            <p>We can’t find any item matching your search.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) :
                        (
                            <React.Fragment>
                                <div className="change-date">
                                    <div className="pull-left">
                                        <div className="prev-next-day">
                                            <span onClick={this.prevDayOutboundFlightSearch}><i className="fa fa-angle-double-left" aria-hidden="true"></i>Prev Day</span>
                                            <span onClick={this.nextDayOutboundFlightSearch}>Next Day<i className="fa fa-angle-double-right" aria-hidden="true"></i></span>
                                        </div>
                                    </div>
                                    <div className="pull-right">
                                        <div className="prev-next-day">
                                            <span onClick={this.prevDayInboundFlightSearch}><i className="fa fa-angle-double-left" aria-hidden="true"></i>Prev Day</span>
                                            <span onClick={this.nextDayInboundFlightSearch}>Next Day<i className="fa fa-angle-double-right" aria-hidden="true"></i></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="title-info">
                                    <div className="pull-left">
                                        {
                                            searchQuery.direct_flight === 'true' &&
                                            <span className="no-found">Found <samp>{(!stopData) ? 0 : stopData.length}</samp> Flight(s)</span>
                                        }
                                        {
                                            searchQuery.direct_flight === 'false' &&
                                            <span className="no-found">Found <samp>{(!totalCheck) ? 0 : totalCheck.length}</samp> Flight(s)</span>
                                        }
                                    </div>
                                    <div className="pull-right">
                                        <div className={`btn-mail ${this.state.sendMailPopup}`}>
                                            <span className="offered-fare-btn btn btn-blue net-fare-button-round" onClick={this.offeredClick.bind(this)}>{this.state.offeredFare ? 'Hide Net Fare ' : 'Show Net Fare '} <i><img src="assets/images/offer-icon.svg" alt="" /></i></span>
                                            { /* eslint-disable-next-line */}
                                            <a className="btn btn-green whatsapp-button" href="javascript:void(0);" onClick={() => this.props.sendItinerariesMessage(this.state.sendMailValues)}><i className="fa fa-whatsapp" aria-hidden="true"></i> Whatsapp </a>
                                            { /* eslint-disable-next-line */}
                                            <a className="btn btn-sky-blue" href="javascript:void(0);" onClick={this.showMainPopup}><i className="fa fa-envelope" aria-hidden="true"></i> Send Email </a>
                                            <div className="email-tooltip">
                                                <div className="tooltip-outer">
                                                    <div className="form-group">
                                                        {
                                                            this.props.sendMailerror && !this.props.ItinerariesMail.loading &&
                                                            <div className="alert alert-danger"> <strong><i className="fa fa-exclamation-triangle"></i>{this.props.sendMailerror}</strong></div>
                                                        }
                                                        {
                                                            this.props.sendMailsuccess && !this.props.ItinerariesMail.loading &&
                                                            <div className="alert alert-success"> <strong> <i className="fa fa-check-circle"></i>Email sent successfully</strong></div>
                                                        }
                                                        <input className="form-control custom-filed" type="email" placeholder="Email Address" onChange={(e) => {
                                                            this.setState({ sendMailAddress: e.target.value });
                                                        }} />
                                                    </div>
                                                    <div className="form-checkbox">
                                                        <div className="select-check">
                                                            <small className="checkbox">
                                                                <input type="checkbox" onChange={(e) => { this.setState({ includeFare: !this.state.includeFare }); }} />
                                                                <label></label>
                                                            </small>
                                                            <span>Include Price?</span>
                                                        </div>
                                                    </div>
                                                    <div className="block">
                                                        <button type="submit" className={`btn btn-primary ${this.props.ItinerariesMail.loading ? 'show' : ''}`} onClick={this.sendMail}>
                                                            <div className="loader-ring"> <span></span>
                                                            </div>Submit</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="table-list">
                                    <div className="inner-table-list">
                                        <div className="table-head">
                                            <div className="table-col airline-col"><span>airline</span></div>
                                            <div className="table-col"> <span>depart</span></div>
                                            <div className="table-col"><span>arrive</span></div>
                                            <div className="table-col"><span>duration</span></div>
                                        </div>
                                        <div className="table-body" id="cont-st" style={window.innerWidth <= 766 ? {} : {overflow:'auto',maxHeight:this.props.track_scroll_position && this.props.track_scroll_position.cliHeight > 1000 ? this.props.track_scroll_position.cliHeight : '1020px',minHeight: '1000px', }}>
                                            <ul>
                                                {
                                                    allSameFlightKeys && allSameFlightKeys.slice(0,this.props.isFilterCheckedStatus).map(function (item, index) {
                                                        if (sameFlightResults[item] && sameFlightResults[item].length > 1) {
                                                            return (
                                                                <React.Fragment key={index}>
                                                                    <FlightSearchDetailsSingleFlight
                                                                        offeredShow={offeredFared}
                                                                        key={sameFlightResults[item][0].result_index}
                                                                        data={sameFlightResults[item][0]}
                                                                        searchQuery={searchQuery}
                                                                        saveEmailData={self.saveEmailData}
                                                                        showFareDetailsPopup={self.showFareDetailsPopup}
                                                                        history={self.props.history}
                                                                        traceId={traceId}
                                                                        setOpenSegment={self.setOpenSegment}
                                                                        {...self.state}
                                                                        param={param}
                                                                        collapseIndex={"collapse" + index}
                                                                        setSelectedCollapse={self.setSelectedCollapse}
                                                                        sameFlightsLength={(sameFlightResults[item].length - 1)}
                                                                    />
                                                                    <Collapse isOpen={(selectedCollapse && selectedCollapse === "collapse" + index) ? true : false} key={"collapse" + index}>
                                                                        {
                                                                            /* eslint-disable-next-line */
                                                                            sameFlightResults[item].map(function (data, key) {
                                                                                if (key > 0 && selectedCollapse==="collapse"+index) {
                                                                                    return (<FlightSearchDetailsSingleFlight
                                                                                        offeredShow={offeredFared}
                                                                                        key={data.result_index}
                                                                                        data={data}
                                                                                        searchQuery={searchQuery}
                                                                                        saveEmailData={self.saveEmailData}
                                                                                        showFareDetailsPopup={self.showFareDetailsPopup}
                                                                                        history={self.props.history}
                                                                                        traceId={traceId}
                                                                                        setOpenSegment={self.setOpenSegment}
                                                                                        {...self.state}
                                                                                        param={param}
                                                                                    />)
                                                                                }
                                                                            })
                                                                        }
                                                                    </Collapse>
                                                                </React.Fragment>)

                                                        }
                                                        else {
                                                            return <FlightSearchDetailsSingleFlight
                                                                offeredShow={offeredFared}
                                                                key={sameFlightResults[item][0].result_index}
                                                                data={sameFlightResults[item][0]}
                                                                searchQuery={searchQuery}
                                                                saveEmailData={self.saveEmailData}
                                                                showFareDetailsPopup={self.showFareDetailsPopup}
                                                                history={self.props.history}
                                                                traceId={traceId}
                                                                setOpenSegment={self.setOpenSegment}
                                                                {...self.state}
                                                                param={param}
                                                            />
                                                        }
                                                    })
                                                }
                                            </ul>
                                            <div className='footers-end'></div>
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>
                        )}
                </div>
                {/*Fare Detail Popup Start*/}
                <div className={this.state.isShowFareDetailpopup === true ? "popup-crad show" : "popup-crad hide"}>
                    <div className="popup-outer">
                        <div className="popup-content">
                            <div className="popup-head">
                                <h2>Fare Rules</h2>
                                <div className="popup-close"><span className="icon icon-cross" onClick={this.showFareDetailsPopup}></span></div>
                            </div>
                            <div className="popup-scorll">
                                {
                                    this.state.vendorFareRule
                                        ?
                                        this.state.vendorFareRule.map(function (data, index) {
                                            if (data.fare_rule_detail) {
                                                let fareRulesDetails = data.fare_rule_detail.replace(/\r\n\t/g, '');
                                                return (
                                                    <React.Fragment key={index}>
                                                        {
                                                            data.origin && data.destination &&
                                                            <h3>{data.origin} - {data.destination}</h3>
                                                        }
                                                        <br />
                                                        <div dangerouslySetInnerHTML={{ __html: fareRulesDetails }} />
                                                    </React.Fragment>
                                                )
                                            }
                                            else {
                                                return (
                                                    <p key={index}><br />Fare Rules not available.<br /></p>
                                                )
                                            }
                                        })
                                        :
                                        this.state.fareRuleSource === 'tripjack' && flightFareRulesData  && 
                                        Object.keys(flightFareRulesData) && flightFareRulesData && Object.keys(flightFareRulesData).length && flightFareRulesData && Object.keys(flightFareRulesData)[0] !== '0' ? 
                                        
                                        Object.keys(fairRules).length > 0 ? <React.Fragment>
                                           <h3>{fairRuleSeatOriginDestination}</h3>
                                           <div className="table-outer">
                                                <div className="table-container content-block-cms">
                                                    <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                                                        <thead>
                                                            <tr>
                                                                <th width="15%">Time Frame</th>
                                                                <th style={{minWidth:'100px'}}>Cancellation Fee</th>
                                                                <th>Date Changes Fee</th>
                                                                <th>No Show</th>
                                                                {/* <th width="16%">Seat Chargeable</th> */}
                                                                
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                                                                                                                        
                                                            Object.keys(fairRules).length > 0 && Object.keys(fairRules).map((fareRule, index)=>{
                                                            
                                                            let fairRuleCancellationFee = fairRules[fareRule]['CANCELLATION'] && fairRules[fareRule]['CANCELLATION']['policyInfo'] ? fairRules[fareRule]['CANCELLATION']['policyInfo'].replaceAll('__nls__', '<br>') : '';
                                                            let fairRuleNoShow = fairRules[fareRule]['NO_SHOW'] && fairRules[fareRule]['NO_SHOW']['policyInfo'] ? fairRules[fareRule]['NO_SHOW']['policyInfo'].replaceAll('__nls__', '<br>') : '';
                                                            let fairRuleDateChange = fairRules[fareRule]['DATECHANGE'] && fairRules[fareRule]['DATECHANGE']['policyInfo'] ? fairRules[fareRule]['DATECHANGE']['policyInfo'].replaceAll('__nls__', '<br>') : '';
                                                            let fairRuleSeatChargeable = fairRules[fareRule]['SEAT_CHARGEABLE'] && fairRules[fareRule]['SEAT_CHARGEABLE']['policyInfo'] ? fairRules[fareRule]['SEAT_CHARGEABLE']['policyInfo'].replaceAll('__nls__', '<br>') : '';
                                                            let fairRuleCancellationFeeAmount = fairRules[fareRule]['CANCELLATION'] && fairRules[fareRule]['CANCELLATION']['amount'] ? '<i class="fa fa-inr" aria-hidden="true"></i>'+fairRules[fareRule]['CANCELLATION']['amount'] : '';
                                                            let fairRuleCancellationFeeAdFee = fairRules[fareRule]['CANCELLATION'] && fairRules[fareRule]['CANCELLATION']['additionalFee'] ? '<i class="fa fa-inr" aria-hidden="true"></i>'+fairRules[fareRule]['CANCELLATION']['additionalFee'] : '';
                                                            let fairRuleDateChangeAmount = fairRules[fareRule]['DATECHANGE'] && fairRules[fareRule]['DATECHANGE']['amount'] ? '<i class="fa fa-inr" aria-hidden="true"></i>'+fairRules[fareRule]['DATECHANGE']['amount'] : '';
                                                            let fairRuleDateChangeAdFee = fairRules[fareRule]['DATECHANGE'] && fairRules[fareRule]['DATECHANGE']['additionalFee'] ? '<i class="fa fa-inr" aria-hidden="true"></i>'+fairRules[fareRule]['DATECHANGE']['additionalFee'] : '';
                                                            let fairRuleNoShowDateSt = fairRules[fareRule]['NO_SHOW'] && fairRules[fareRule]['NO_SHOW']['st'] ? fairRules[fareRule]['NO_SHOW']['st'].replaceAll('__nls__', '<br>') + ' hr' + ' - ' : '';
                                                            let fairRuleNoShowDateEt = fairRules[fareRule]['NO_SHOW'] && fairRules[fareRule]['NO_SHOW']['et'] ? (fairRules[fareRule]['NO_SHOW']['et'].replaceAll('__nls__', '<br>')/24) + ' days': '';
                                                            fairRuleCancellationFee  = (fairRuleCancellationFee === 'Not Applicable') || (fairRuleCancellationFee === 'CANCELLATION Not Available') || (fairRuleCancellationFee === "Not Refundable") ? fairRuleCancellationFee : fairRuleCancellationFeeAmount+fairRuleCancellationFeeAdFee && "  "+fairRuleCancellationFeeAmount +" "+  fairRuleCancellationFee;
                                                            fairRuleDateChange = fairRuleDateChange === 'Not Applicable' || (fairRuleDateChange === "Not Refundable") ? fairRuleDateChange :  fairRuleDateChangeAmount+fairRuleDateChangeAdFee && "  "+fairRuleDateChangeAmount +" "+ fairRuleDateChange;  
                                                            
                                                            return (<tr key= {index}>
                                                                <td><div dangerouslySetInnerHTML={{__html: fareRule.replaceAll("_"," ")}}/></td>
                                                                <td><div dangerouslySetInnerHTML={{ __html: fairRuleCancellationFee}}/> </td>
                                                                <td><div  dangerouslySetInnerHTML={{ __html: fairRuleDateChange}}/> </td>
                                                                <td><div  dangerouslySetInnerHTML={{ __html: fairRuleNoShow }}/></td>
                                                                {/* <td><div  dangerouslySetInnerHTML={{ __html: fairRuleSeatChargeable }}/></td> */}
                                                            </tr>)
                                                             })
                                                             }
                                                        </tbody>
                                                    </table>
                                                    </div>
                                            </div>
                                            
                                        </React.Fragment> :  <p><br />Fare Rules not available.<br /></p>

                                    //     : this.state.fareRuleSource === 'kafila' && this.state.kafilaFareRules ? <React.Fragment>
                                    //     <h3>{this.props.searchQuery && this.props.searchQuery.originAirportCode}-{this.props.searchQuery && this.props.searchQuery.destinationAirportCode} </h3>
                                    //     <div className="table-outer">
                                    //          <div className="table-container">
                                    //              <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                                    //                  <thead>
                                    //                      <tr>
                                    //                          <th width="15%">Time Frame</th>
                                    //                          <th>Cancellation Fee</th>
                                    //                          <th>Date Changes Fee</th>
                                    //                         { /*<th>No Show</th>*/}
                                    //                          {/* <th width="16%">Seat Chargeable</th> */}
                                                             
                                    //                      </tr>
                                    //                  </thead>
                                    //                  <tbody>
                                    //                      {
                                    //                         this.state.kafilaFareRules && this.state.kafilaFareRules.length>0 &&  this.state.kafilaFareRules.map((item)=>{
                                    //                                 return  (<tr key= {""}>
                                    //                                 <td><div dangerouslySetInnerHTML={{__html: item.time_frame}}/></td>
                                    //                                 <td><div dangerouslySetInnerHTML={{ __html: item.cancellation_fee}}/> </td>
                                    //                                 <td><div  dangerouslySetInnerHTML={{ __html: item.date_change_fee}}/> </td>
                                    //                                 {/*<td><div  dangerouslySetInnerHTML={{ __html: "fairRuleNoShow" }}/></td>*/}
                                    //                                 {/* <td><div  dangerouslySetInnerHTML={{ __html: fairRuleSeatChargeable }}/></td> */}
                                    //                             </tr>)
                                    //                         })                                                                                                          
                                    //                       }
                                    //                  </tbody>
                                    //              </table>
                                    //              <br />
                                    //                  <p>The airline charges is indicative, which will depend upon the time of cancellation / re-issue as per the airline fare rules.<br /></p>
                                    //                  <p>Mentioned Charges are Per Pax Per Sector<br /></p>
                                    //                  <p>Apart from airline charges, GST + RAF + applicable charges if any, will be charged.<br /></p>
                                    //                  <p>For more information Please contact our helpdesk.<br /></p>
                                    //          </div>
                                    //      </div>
                                    //  </React.Fragment>:
                                    : this.state.fareRuleSource === 'tripjack' && flightFareRulesData && 
                                    Object.keys(flightFareRulesData) && flightFareRulesData && Object.keys(flightFareRulesData).length && flightFareRulesData && Object.keys(flightFareRulesData)[0] === '0' ? 
                                    flightFareRulesData.map(function (data, index) {
                                        let fareRulesDetails = data.fare_rule_detail.replace(/\r\n\t/g, '');
                                        return (
                                            <React.Fragment key={index}>
                                                <h3>{data.origin} - {data.destination}</h3>
                                                <div dangerouslySetInnerHTML={{ __html: fareRulesDetails }} />
                                            </React.Fragment>
                                        )
                                    }) : 
                                        ((!this.props.flightFareRuleDetails.response && this.props.flightFareRuleDetails.loading === true) ||
                                         (!this.props.tripJackFlightFareRuleDetails.response && this.props.tripJackFlightFareRuleDetails.loading === true) ||
                                          (!this.props.kafilaFlightFareRuleDetails.response && this.props.kafilaFlightFareRuleDetails.loading === true) ||
                                           (!this.props.airIQFlightFareRuleDetails.response && this.props.airIQFlightFareRuleDetails.loading === true) ||
                                           (!this.props.satkarFlightFareRuleDetails.response && this.props.satkarFlightFareRuleDetails.loading === true)||
                                           (!this.props.fareBoutiqueFlightFareRuleDetails.response && this.props.fareBoutiqueFlightFareRuleDetails.loading === true) ||
                                           (!this.props.riyaFlightFareRuleDetails.response && this.props.riyaFlightFareRuleDetails.loading === true)) ?
                                            (<div className="loader relative-loader" style={{ display: 'block' }}>
                                                <div className="loader-content">
                                                    <div className="loader-ring blue-ring"> <span></span> </div>
                                                </div>
                                            </div>)
                                            :
                                            flightFareRulesData && flightFareRulesData.length > 0 ? flightFareRulesData.map(function (data, index) {
                                                let fareRulesDetails = data.fare_rule_detail.replace(/\r\n\t/g, '');
                                                return (
                                                    <React.Fragment key={index}>
                                                        <h3 className='clear_both'>{data.origin} - {data.destination}</h3>
                                                        <div className='content-block-cms'>
                                                        {fareRulesDetails ? (
                                                                <div dangerouslySetInnerHTML={{ __html: fareRulesDetails }} />
                                                            ) : (
                                                                <div>Fare Rules Are Not Available</div>
                                                            )}
                                                        </div>
                                                    </React.Fragment>
                                                )
                                            }): <React.Fragment>Fare Rules Are Not Available</React.Fragment>
                                }
                                {!this.state.vendorFareRule && !flightFareRulesData && this.props.flightFareRuleDetails.loading === false && this.props.kafilaFlightFareRuleDetails.loading === true && this.props.airIQFlightFareRuleDetails.loading === true && this.props.satkarFlightFareRuleDetails.loading === true && this.props.fareBoutiqueFlightFareRuleDetails.loading === true && this.props.riyaFlightFareRuleDetails.loading === true&& this.props.tripJackFlightFareRuleDetails.loading === false? <p><br />Fare Rules not available.<br /></p> : ''}
                            </div>
                        </div>
                    </div>
                    <div className="popup-overlay"></div>
                </div>
                {/*Fare Detail Popup End*/}
            </div>
        )
    }
}

class FlightSearchDetailsSingleFlight extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            isOpenSegment: '',
            isDestinationSame: false,
            somethingWrong: false
        };
    }

    toggleDetailsAccordion = (event, uniqueIndex) => {
        this.setState({ isOpenSegment: uniqueIndex })

        if (uniqueIndex === this.state.isOpenSegment) {
            this.setState({ isOpen: this.state.isOpen === true ? false : true })
        }
        else {
            this.setState({ isOpen: true })
        }
    }

    emailData = (event) => {
        this.props.saveEmailData(event);
    }

    fareData = (event, result_index, is_vendor, fare_rules, segments, api_source,uid,airline_remark,supplier_airline_remark,flightId,trackId) => {
        let airline_code = segments.length && segments[0].length && segments[0][0].airline && segments[0][0].airline.airline_code;
        this.props.showFareDetailsPopup(event, result_index, is_vendor, fare_rules, segments, api_source,uid,airline_remark,airline_code,supplier_airline_remark,flightId,trackId);
    }
    
    //Start booking of flight
    startBooking = (data) => {
        const searchQueryDestionationOnward = this.props.searchQuery && this.props.searchQuery.segments && this.props.searchQuery.segments[0].destinationAirportCode;
        const searchQueryOriginOnward = this.props.searchQuery && this.props.searchQuery.segments && this.props.searchQuery.segments[0].originAirportCode;

        const searchQueryDestionationReturn = this.props.searchQuery && this.props.searchQuery.segments && this.props.searchQuery.segments[1].destinationAirportCode;
        const searchQueryOriginReturn = this.props.searchQuery && this.props.searchQuery.segments && this.props.searchQuery.segments[1].originAirportCode;
        
        
        const dataDestinationOnward = data &&
            data.segments &&
            data.segments[0] &&
            data.segments[0][data.segments[0].length - 1] &&
            data.segments[0][data.segments[0].length - 1].destination &&
            data.segments[0][data.segments[0].length - 1].destination.airport &&
            data.segments[0][data.segments[0].length - 1].destination.airport.airport_code;
        
        const dataDestinationReturn = data &&
            data.segments &&
            data.segments[1] &&
            data.segments[1][data.segments[1].length - 1] &&
            data.segments[1][data.segments[1].length - 1].destination &&
            data.segments[1][data.segments[1].length - 1].destination.airport &&
            data.segments[1][data.segments[1].length - 1].destination.airport.airport_code;

            const dataOriginOnward = data &&
            data.segments &&
            data.segments[0] &&
            data.segments[0][0] &&
            data.segments[0][0].origin &&
            data.segments[0][0].origin.airport &&
            data.segments[0][0].origin.airport.airport_code;
        
        const dataOriginReturn = data &&
            data.segments &&
            data.segments[1] &&
            data.segments[1][0] &&
            data.segments[1][0].origin &&
            data.segments[1][0].origin.airport &&
            data.segments[1][0].origin.airport.airport_code;
        if ((searchQueryDestionationOnward !== dataDestinationOnward) || (searchQueryDestionationReturn !== dataDestinationReturn) || (searchQueryDestionationReturn !== dataDestinationReturn) || (searchQueryOriginReturn !== dataOriginReturn)) {
            this.setState({ isDestinationSame: !this.state.isDestinationSame });
        }else{
            let bookingParam = this.props.param ? this.props.param[0] : '';
            this.props.history.push({
                pathname: "/book-ticket",
                state: {
                    searchType: 'special-return',
                    trace_id: this.props.traceId,
                    data: data,
                    param: bookingParam
                }
            });
        }
    }

    sameFlightAccordian = () => {
        const {collapseIndex} = this.props;
        this.setState({isOpen: !this.state.isOpen}, function()
        {
            if(this.state.isOpen === true)
                this.props.setSelectedCollapse(collapseIndex);
            else
                this.props.setSelectedCollapse(null);
        });
    }

    handleConfirmationPopup = (action) => {
		this.setState({
			isDestinationSame: action,
		})
	}

    handleConfirmationPopupWrong = (action) => {
		this.setState({
			somethingWrong: action,
		})
	}

	handleConfimationAction = (data) => {
		this.setState({
			isDestinationSame: false
		})
        const segmentZeroCheck = data.segments &&
        data.segments[0] &&
        data.segments[0][data.segments[0].length - 1] &&
        data.segments[0][data.segments[0].length - 1].destination &&
        data.segments[0][data.segments[0].length - 1].destination.airport &&
        data.segments[0][data.segments[0].length - 1].destination.airport.airport_code;
        const segmentOneCheck = data.segments &&
        data.segments[1] &&
        data.segments[1][data.segments[1].length - 1] &&
        data.segments[1][data.segments[1].length - 1].destination &&
        data.segments[1][data.segments[1].length - 1].destination.airport &&
        data.segments[1][data.segments[1].length - 1].destination.airport.airport_code;
        
        if (segmentOneCheck === undefined || segmentZeroCheck === undefined) {
            this.setState({
                somethingWrong: true
            })
        }else{
		let bookingParam = this.props.param ? this.props.param[0] : '';
            this.props.history.push({
                pathname: "/book-ticket",
                state: {
                    searchType: 'special-return',
                    trace_id: this.props.traceId,
                    data: data,
                    param: bookingParam
                }
            });
        }
	}

    render() {
        let data = this.props.data;
        let segments = data.segments;
        let segmentData = data.segments[0];
        let self = this;
        let { isOpen, isOpenSegment } = this.state;
        let searchQuery = this.props.searchQuery;
        let stopData = flightHelper.getFlightStops(segmentData);
        const {collapseIndex, sameFlightsLength} = this.props;
        
        function timeConversion(n) {	
            var num = n;	
            var hours = (num / 60);	
            var rhours = Math.floor(hours);	
            if (rhours.toString().length < 2)	
                rhours = "0" + rhours;	
            var minutes = (hours - rhours) * 60;	
            var rminutes = Math.round(minutes);	
            if (rminutes.toString().length < 2)	
                rminutes = "0" + rminutes;	
            return rhours + 'h' + ':' + rminutes + 'm';	
        }

        let noOfSeatAvailable = 0
        let noOfSeatAvailableData=[]
        data && data.segments.map((item => {
             let result = item.reduce((prev, curr) => prev.no_of_seat_available < curr.no_of_seat_available ? prev : curr);
             noOfSeatAvailable = result.no_of_seat_available
             noOfSeatAvailableData.push(noOfSeatAvailable)
        }))
        const searchQueryDestionationOnward = this.props.searchQuery && this.props.searchQuery.segments && this.props.searchQuery.segments[0].destinationAirportCode;
        const searchQueryOriginOnward = this.props.searchQuery && this.props.searchQuery.segments && this.props.searchQuery.segments[0].originAirportCode;

        const searchQueryDestionationReturn = this.props.searchQuery && this.props.searchQuery.segments && this.props.searchQuery.segments[1].destinationAirportCode;
        const searchQueryOriginReturn = this.props.searchQuery && this.props.searchQuery.segments && this.props.searchQuery.segments[1].originAirportCode;
        
        
        const dataDestinationOnward = data &&
            data.segments &&
            data.segments[0] &&
            data.segments[0][data.segments[0].length - 1] &&
            data.segments[0][data.segments[0].length - 1].destination &&
            data.segments[0][data.segments[0].length - 1].destination.airport &&
            data.segments[0][data.segments[0].length - 1].destination.airport.airport_code;
        
        const dataDestinationReturn = data &&
            data.segments &&
            data.segments[1] &&
            data.segments[1][data.segments[1].length - 1] &&
            data.segments[1][data.segments[1].length - 1].destination &&
            data.segments[1][data.segments[1].length - 1].destination.airport &&
            data.segments[1][data.segments[1].length - 1].destination.airport.airport_code;

            const dataOriginOnward = data &&
            data.segments &&
            data.segments[0] &&
            data.segments[0][0] &&
            data.segments[0][0].origin &&
            data.segments[0][0].origin.airport &&
            data.segments[0][0].origin.airport.airport_code;
        
        const dataOriginReturn = data &&
            data.segments &&
            data.segments[1] &&
            data.segments[1][0] &&
            data.segments[1][0].origin &&
            data.segments[1][0].origin.airport &&
            data.segments[1][0].origin.airport.airport_code;

         
        return (
            (searchQuery.direct_flight === 'true' && (data.api_source === 'tripjack' || data.api_source === 'kafila' || data.api_source === 'tbo') && (stopData[0] && stopData[0].stop_over && ((stopData[0].stop_over.length > 0 && stopData[0].stop_over[0] && stopData[0].stop_over[0].cityCode) || (stopData[0].stop_over === true)))) ?
            null : 
            <li className={this.props.openSegments === data.result_index ? "open" : ""} id='calcHeight'>
             {/* <div className="table-block"> */}
                <div className="col-table-inner">
                    <ul className="col-content">
                        {
                            segments && segments.map(function (segment, segmentIndex) {
                                // let originCityCode = segment[0].origin.airport.airport_code;
                                // let destinationCityCode = segment[segment.length-1].destination.airport.airport_code;
                                let segmentData = segment;
                                let stopData = flightHelper.getFlightStops(segmentData);
                                return (
                                    <li className={isOpen === true && isOpenSegment === data.result_index + '_' + segmentIndex ? "open" : ""} key={segmentIndex}>
                                        <div className="col-table-detail">
                                            <div className="table-col airline-col">
                                                <div className="col-list-name">
                                                    <div className="company-info">
                                                        <span className="mob-label">Airline</span>
                                                        <figure><img src={segment[0].airline.flight_logo} alt="" /></figure>
                                                        <div className="company-nm">
                                                            <span>{flightHelper.getCombinedAirlineName(segment)}</span>
                                                            <small>{flightHelper.getCombinedAirlineCode(segment)}</small>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="table-col">
                                                <span className="mob-label">Depart</span>
                                                <div className="col-detail">
                                                    {
                                                        flightHelper.getGDSDeparuteCityTime(segment)
                                                    }
                                                    <small>{flightHelper.getDepartureDate(segment[0].origin.dep_time, 'DD MMM')}</small>
                                                </div>
                                            </div>
                                            <div className="table-col">
                                                <span className="mob-label">Arrive</span>
                                                <div className="col-detail">
                                                    {
                                                        flightHelper.getGDSArrivalCityTime(segment)
                                                    }
                                                    {/* <span><samp>{destinationCityCode}</samp> 
                                                    ({flightHelper.getArivalTime(segment)})
                                                </span>  */}
                                                    <small>{flightHelper.getArivalDate(segment)}</small>
                                                </div>
                                            </div>
                                            <div className="table-col">
                                                <span className="mob-label">Duration</span>
                                                <div className="col-detail">
                                                    <span>{flightHelper.getCommonDuration(segment, ':',data.api_source,data)}</span>
                                                    { /* eslint-disable-next-line */}
                                                    <a className="non-stop-click" href="javascript:void(0)">
                                                        <small>{flightHelper.getJournyType(stopData)}</small>
                                                        <div className="non-stop-tooltip">
                                                            <ul>
                                                                {
                                                                    stopData.map(function (segment, segmentIndex) {
                                                                        return (
                                                                            <li key={segmentIndex}>
                                                                                <div className="col-stop">{segment.airline_code + '-' + segment.flight_number}</div>
                                                                                <div className="col-stop">{segment.origin_airport_code + (segment.origin_dep_time ? " (" + timeFormatHhMm(segment.origin_dep_time) + ")" : '')}</div>
                                                                                <div className="col-stop last-stop">
                                                                                    <i className="icon icon-arrow-right2"></i>
                                                                                    {segment.destination_airport_code + ( segment.destination_arr_time ? " (" + timeFormatHhMm(segment.destination_arr_time) + ")" : '')}
                                                                                    {segment.craft && segment.remark && segment.craft === "BUS" ? <samp className="red-text">*</samp> : ""}</div>
                                                                                {
                                                                                    (segment.techStop) &&
                                                                                    <div className="techstopmsg">1 Technical Stop</div>
                                                                                }
                                                                            </li>
                                                                        )
                                                                    })
                                                                }
                                                            </ul>
                                                        </div>
                                                    </a>
                                                    <small className="left-seat">{noOfSeatAvailable !== null ? flightHelper.kafilaNumberOfSeat(noOfSeatAvailableData[segmentIndex]) + ' seat(s) left' : ''}</small>
                                                         {/* NearBy Airport flag*/}
                                                            {/*  Onwards Segment Check*/}
                                                    {segmentIndex === 0 && ((searchQueryDestionationOnward !== dataDestinationOnward) || (searchQueryOriginOnward !== dataOriginOnward )) && (
                                                        <div
                                                            style={{
                                                                display: 'inline-block',
                                                                padding: '1px 4px',
                                                                backgroundColor: '#e3eaf2',
                                                                color: '#0f6dbf',
                                                                fontSize: '12px',
                                                                fontWeight: 'bold',
                                                                borderRadius: '3px',
                                                                textAlign: 'center',
                                                                whiteSpace: 'nowrap',
                                                                marginTop: '5px',
                                                                border: '1px solid #b3c7e6',
                                                                width: '100%',
                                                                clear: 'both',
                                                                
                                                            }}
                                                        >
                                                            Nearby Airport
                                                        </div>
                                                    )}

                                                    {/* Return Segment Check */}
                                                    {segmentIndex === 1 && ((searchQueryDestionationReturn !== dataDestinationReturn) || (searchQueryOriginReturn !== dataOriginReturn)) && (
                                                        <div
                                                            style={{
                                                                display: 'inline-block',
                                                                padding: '1px 4px',
                                                                backgroundColor: '#e3eaf2',
                                                                color: '#0f6dbf',
                                                                fontSize: '12px',
                                                                fontWeight: 'bold',
                                                                borderRadius: '3px',
                                                                textAlign: 'center',
                                                                whiteSpace: 'nowrap',
                                                                marginTop: '5px',
                                                                border: '1px solid #b3c7e6',
                                                                width: '100%',
                                                                clear: 'both'
                                                            }}
                                                        >
                                                            Nearby Airport 
                                                        </div>
                                                    )}
                                                    </div>
                                            </div>
                                            <span className="block-title acc-block"><i className="fa fa-angle-down" aria-hidden="true" onClick={(event) => self.toggleDetailsAccordion(event, data.result_index + '_' + segmentIndex)}></i></span>
                                        </div>
                                        {/********************************* Flight stop details *************************************/}
                                        <div className="block-accordion" style={{ display: 'block' }}>
                                            <div className="block-describe">
                                                <div className={searchQuery.flightCabinClassName === 'Premium Economy'? "info-detail premium-info-detail" : searchQuery.flightCabinClassName === 'Economy'? "economy-info-detail" :"info-detail"}>
                                                    <div className="info-title">
                                                        <div className="col-left-info">
                                                            <div className="city-to-other"><span>{searchQuery.segments[segmentIndex].originCityName}</span> <small><img src="assets/images/flight-continue.svg" alt="" /></small><span>{searchQuery.segments[segmentIndex].destinationCityName}</span></div>
                                                            <div className="detail-time-info">
                                                                <ul className="flight-info">
                                                                    <li><span>{flightHelper.getDepartureDate(segment[0].origin.dep_time, 'FDAY, DD MMM')}</span></li>
                                                                    <li><span>{flightHelper.getCommonDuration(segment, ':',data.api_source,data)}</span></li>
                                                                    <li><span>{flightHelper.getJournyType(stopData)}</span></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="col-mid-info">{flightHelper.isRefundable(data.is_refundable)}</div>
                                                        <div className="col-right-info">
                                                            <div className="total-amount"><span><i className="fa fa-inr" aria-hidden="true"></i>{currencyFormat(data.fare.published_fare)}</span>
                                                                <div className="tooltip-amount">
                                                                    { /* eslint-disable-next-line */}
                                                                    <a className="tooltip-link" href="javascript:void(0);">Fare Breakup</a>
                                                                    <div className="amount-detail">
                                                                        <ul>
                                                                            <li>Base Fare <span><i className="fa fa-inr" aria-hidden="true"></i>{currencyFormat(data.fare.base_fare)}</span></li>
                                                                            {
                                                                                data.fare.other_charges !== 0
                                                                                    ? <li>Other Charges <span><i className="fa fa-inr" aria-hidden="true"></i>{currencyFormat(data.fare.other_charges)}</span></li>
                                                                                    : ''
                                                                            }

                                                                            {
                                                                                data.fare.tax !== 0
                                                                                    ? <li>Tax <span><i className="fa fa-inr" aria-hidden="true"></i>{currencyFormat(data.fare.tax)}</span></li>
                                                                                    : ''
                                                                            }

                                                                            {
                                                                                data.fare.yq_tax !== 0
                                                                                    ? <li>YQ Tax (Included)<span><i className="fa fa-inr" aria-hidden="true"></i>{currencyFormat(data.fare.yq_tax)}</span></li>
                                                                                    : ''
                                                                            }

                                                                            {
                                                                                data.fare.other_tax !== 0
                                                                                    ? <li>Other Tax<span><i className="fa fa-inr" aria-hidden="true"></i>{currencyFormat(data.fare.other_tax)}</span></li>
                                                                                    : ''
                                                                            }

                                                                            {
                                                                                data.fare.service_charge !== 0
                                                                                    ? <li>Service Charge<span><i className="fa fa-inr" aria-hidden="true"></i>{currencyFormat(data.fare.service_charge)}</span></li>
                                                                                    : ''
                                                                            }

                                                                            {/* {
                                                    data.fare.discount+data.fare.agent_discount !== 0 
                                                    ?<li>Discount <span><i className="fa fa-inr" aria-hidden="true"></i>{currencyFormat(data.fare.discount+data.fare.agent_discount)}</span></li>
                                                    :''
                                                } */}
                                                                            <li className="last-amount-total"> <span><small>Total:</small><i className="fa fa-inr" aria-hidden="true"></i>{currencyFormat(data.fare.published_fare)}</span></li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="sub-info"><span>({flightHelper.getPaxSummary(searchQuery.adult_count, searchQuery.child_count, searchQuery.infant_count)})</span></div>
                                                        </div>
                                                    </div>
                                                    <div className="time-duration-section">
                                                        <ul className="flight-duration">
                                                            {
                                                                segment && segment.map(function (stopSegment, stopSegmentIndex) {
                                                                    return (
                                                                        <li key={stopSegmentIndex}>
                                                                            {
                                                                                stopSegmentIndex !== 0
                                                                                    ? <div className="time-col"><span>Change plane at {stopSegment.origin.airport.city_name}</span></div>
                                                                                    : ""
                                                                            }
                                                                            <div className="detail-bx">
                                                                                <div className="fl-info">
                                                                                        <div className="fl-name">
                                                                                            <span>{flightHelper.getAirlineNameAndCode(stopSegment)}</span>
                                                                                            {
                                                                                                (data.api_source === 'tripjack' || data.api_source === 'tbo') &&
                                                                                                <small>{flightHelper.getAirlineOperatingCarrier(stopSegment)}</small>
                                                                                            }
                                                                                            {
                                                                                                data.api_source !== 'kafila' && 
                                                                                                <small>Aircraft - {stopSegment.craft}</small>
                                                                                            }
                                                                                        </div>
                                                                                    {/* Layover Start */}
                                                                                    <div className="fl-information">
                                                                                        {
                                                                                            stopSegmentIndex !== 0 && timeDifference(segment[stopSegmentIndex - 1].destination.arr_time, stopSegment.origin.dep_time) > 0
                                                                                                ? <span> Layover {minutesToDuration(timeDifference(segment[stopSegmentIndex - 1].destination.arr_time, stopSegment.origin.dep_time), 'h')}</span>
                                                                                                : ""
                                                                                        }
                                                                                    </div>
                                                                                    {/* Layover End */}
                                                                                    {stopSegment.craft && stopSegment.remark && stopSegment.craft === "BUS" ? <div className="fn-segment"><span className="red-text">{stopSegment.remark}</span></div> : ""}
                                                                                </div>
                                                                                <div className="fl-detail">
                                                                                    <div className="fl-inner">
                                                                                        <div className="fl-col">
                                                                                            <span> {stopSegment.origin.airport.city_name} </span> <small> {dateFormat(stopSegment.origin.dep_time, "DAY, DD MMM, HH:MM")} </small>
                                                                                            <div className="terminal-nm"> <span> {stopSegment.origin.airport.airport_name}{showValue(" - T-", stopSegment.origin.airport.terminal, "")}</span> </div>
                                                                                        </div>
                                                                                        <div className="fl-mid">
                                                                                            <div className="fl-mid-inner">
                                                                                                <ul>
                                                                                                    <li>
                                                                                                        <i className="fa fa-clock-o" aria-hidden="true"></i> <span>{timeConversion(stopSegment.duration, 'h')}</span>
                                                                                                    </li>
                                                                                                    {
                                                                                                        stopSegment.cabin_baggage !== null
                                                                                                            ? (<li className="mid-meal"> <i className="material-icons">card_travel</i> <span>{stopSegment.cabin_baggage}</span> </li>)
                                                                                                            : ""
                                                                                                    }
                                                                                                    {
                                                                                                        stopSegment.baggage !== null
                                                                                                            ? (<li> <i className="material-icons">shopping_cart</i> <span>{stopSegment.baggage}</span> </li>)
                                                                                                            : ""
                                                                                                    }
                                                                                                </ul>
                                                                                            </div>
                                                                                            {
                                                                                                data.api_source === 'tripjack' && //stopSegment.length === 1 &&
                                                                                                <div className="time-col" style={{
                                                                                                    display: (
                                                                                                        (stopSegment && stopSegment.stop_over && stopSegment.stop_over.length >= 1) ? 'show' : 'none')

                                                                                                }}>
                                                                                                    <span>Technical Stop Via {flightHelper.getCityCodeTripJack(stopSegment)}</span>
                                                                                                </div>
                                                                                            }

                                                                                            {
                                                                                                (data.api_source === 'tbo' || data.api_source === 'kafila' || data.api_source === 'riya_travel') && //stopSegment.length === 1 &&
                                                                                                <div className="time-col" style={{
                                                                                                    display: (
                                                                                                        (stopSegment && stopSegment.stop_over) ? 'show' : 'none')

                                                                                                }}>
                                                                                                    <span>Technical Stop Via {stopSegment && stopSegment.stop_point_full_name}</span>
                                                                                                </div>
                                                                                            }
                                                                                            <div className="mid-line flight-cabin"> <small className="dot"></small> <small className="dot"></small> <span className="flight-icon"><img src="assets/images/flight-white.svg" alt="" /></span> </div>
                                                                                            {
                                                                                                stopSegment.cabin_class_name ?
                                                                                                    <div className='cabin-info' style={{ marginTop: '0px' }}>
                                                                                                        <strong style={{ color: '#fff' }}>Cabin : </strong>
                                                                                                        <span style={{ color: '#fff' }}>{stopSegment.cabin_class_name}</span>
                                                                                                    </div> :
                                                                                                    null
                                                                                            }
                                                                                        </div>
                                                                                        <div className="fl-col">
                                                                                            <span> {stopSegment.destination.airport.city_name} </span> <small> {dateFormat(stopSegment.destination.arr_time, "DAY, DD MMM, HH:MM")} </small>
                                                                                            <div className="terminal-nm"> <span> {stopSegment.destination.airport.airport_name} {showValue(" - T-", stopSegment.destination.airport.terminal, "")}</span> </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </li>)
                                                                })
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/********************************* Flight stop details *************************************/}
                                    </li>
                                )
                            })
                        }
                    </ul>
                    <div className="bottom-table-col">
                        {
                             <div className="fl-sme">
                                <span>
                                    {
                                       (data.is_remark_available === true || data.is_vendor === 1) && (data.airline_remark ? data.airline_remark : '') 
                                    }
                                </span>
                            </div>
                        }
                        <div className="price-col">
                            <span className="money-total">
                                <i className="fa fa-inr" aria-hidden="true"></i>{currencyFormat(data.fare.published_fare)}
                            </span>
                            <small className="offered-fare" style={{ display: (this.props.offeredShow === true) ? 'block' : 'none' }}><i className="fa fa-inr" aria-hidden="true"></i>{currencyFormat(data.fare.offered_fare)}</small>
                            <ul className="list-icon">
                                {/* Baggage Icon  --------------------------------------------------------------------- Start here */}
                                <li className="cabin-list">
                                    { /* eslint-disable-next-line */}
                                    <span className="select-tooltip">
                                        <i className="fa icon-bag" aria-hidden="true"></i>
                                        <div className="select-operated-tooltip">
                                            <div className="inner-tooltip-card">
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th>Sector</th>
                                                            <th>Cabin</th>
                                                            <th>Check-in</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            segments && segments.map(function (bagSegment, bagSegmentIndex) {
                                                                return (
                                                                    <tr key={bagSegmentIndex}>
                                                                        <td><span>{bagSegment[0].origin.airport.airport_code}</span> - <span> {bagSegment[bagSegment.length - 1].destination.airport.airport_code}</span></td>
                                                                        <td>{bagSegment[0].cabin_baggage ? bagSegment[0].cabin_baggage : '-'}</td>
                                                                        <td>{bagSegment[0].baggage ? bagSegment[0].baggage : '-'}</td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </span>
                                </li>
                                {/* Fare Rule Icon  --------------------------------------------------------------------- Start here */}
                                <li title="Fare Rules">
                                    { /* eslint-disable-next-line */}
                                    <a href="/" onClick={(event) => {event.preventDefault(); this.fareData(event, data.result_index, data.is_vendor, data.fare_rules, data.segments, data.api_source,data.uid,data.airline_remark,data.supplier_airline_remark,data.flightId,data.trackId)}}>
                                        <i className="fa icon-edit-right" aria-hidden="true"></i>
                                    </a>
                                </li>
                                {/* Is Refundable Icon  --------------------------------------------------------------------- Start here */}
                                {
                                    data.is_refundable
                                        ?
                                        <li title="Refundable">
                                            { /* eslint-disable-next-line */}
                                            <a href="javascript:void(0)">
                                                <i className="fa icon icon-reg" aria-hidden="true"></i>
                                            </a>
                                        </li>
                                        :
                                        ""
                                }
                            </ul>
                            <div className="button-block">
                                <span className="mail-icon">
                                    <input type="checkbox" value={data.result_index} name="sendMail" onChange={(event) => this.emailData(event)} />
                                    <i className="icon icon-mail1"></i> <i className="icon icon-checked"></i></span>
                                { /* eslint-disable-next-line */}
                                <a className="btn btn-yellow" onClick={() => this.startBooking(data)}>Book Now</a>
                            </div>
                        </div>
                    </div>
                </div>
             {/* </div> */}
            {
                collapseIndex &&
                <div className="same-flight-widget">
                    {/* eslint-disable-next-line */}
                    <a href="javascript:void(0);" onClick={() => this.sameFlightAccordian()} className={this.state.isOpen ? "same-flight-btn active" : "same-flight-btn"}><span>+{sameFlightsLength} More Fares</span><i className="icon icon icon-arrow-down"></i></a>
                </div>
            }
            <div className={`popup-crad confirmation-popup ${this.state.isDestinationSame ? 'show' : ''}`}>
                <div className="popup-outer">
                    <div className="popup-content" style={{ maxWidth: '373px' }}>
                        <div className="popup-head">
                            <h2>Different Airport</h2>
                            <div className="popup-close"><span className="icon icon-cross" onClick={() => this.handleConfirmationPopup(false)} /></div>
                        </div>
                        <div className="popup-body popup-scorll">

                            {
                                ((searchQueryDestionationOnward !== dataDestinationOnward) || (searchQueryOriginOnward !== dataOriginOnward )) &&
                                <div className="inner-confirmation">
                                    <p>
                                        {`You have searched for Onward (`}
                                        <strong>
                                            {`${this.props.searchQuery.segments[0].originAirportCode} - ${this.props.searchQuery.segments[0].destinationAirportCode}`}
                                        </strong>
                                        {`)`}
                                    </p>
                                    <p>
                                        {`however, this flight option for Onward (`}
                                        <strong>
                                            {this.props.searchQuery.segments[0].originAirportCode} -
                                            {data && data.segments && data.segments[0] && data.segments[0][data.segments[0].length - 1] && data.segments[0][data.segments[0].length - 1].destination && data.segments[0][data.segments[0].length - 1].destination.airport && data.segments[0][data.segments[0].length - 1].destination.airport.airport_code}
                                        </strong>
                                        {`)`}
                                    </p>
                                </div>
                            }
                            {
                                ((searchQueryDestionationReturn !== dataDestinationReturn) || (searchQueryOriginReturn !== dataOriginReturn)) &&
                                <div className="inner-confirmation">
                                    <p>
                                        {`You have searched for Return (`}
                                        <strong>
                                            {`${this.props.searchQuery.segments[1].originAirportCode} - ${this.props.searchQuery.segments[1].destinationAirportCode}`}
                                        </strong>
                                        {`)`}
                                    </p>
                                    <p>
                                        {`however, this flight option for Return (`}
                                        <strong>
                                            {data && data.segments && data.segments[1] && data.segments[1][0] && data.segments[1][0].origin && data.segments[1][0].origin.airport && data.segments[1][0].origin.airport.airport_code} -
                                            {data && data.segments && data.segments[1] && data.segments[1][data.segments[1].length - 1] && data.segments[1][data.segments[1].length - 1].destination && data.segments[1][data.segments[1].length - 1].destination.airport && data.segments[1][data.segments[1].length - 1].destination.airport.airport_code}
                                        </strong>
                                        {`)`}
                                    </p>
                                </div>
                            }


                            <div className="inner-confirmation">
                                <p>Are you sure you want to continue?</p>
                                <div className="popup-footer">
                                    <button className="btn btn-orange" onClick={() => this.handleConfimationAction(data)}>Yes</button>
                                    <button className="btn btn-grey" onClick={() => this.handleConfirmationPopup(false)}>No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="popup-overlay" />
            </div>
            <div className={`popup-crad confirmation-popup ${this.state.somethingWrong ? 'show' : ''}`}>
                <div className="popup-outer">
                    <div className="popup-content" style={{ maxWidth: '373px' }}>
                        <div className="popup-head">
                            <h2>Something Went Wrong</h2>
                            <div className="popup-close"><span className="icon icon-cross" onClick={() => this.handleConfirmationPopupWrong(false)} /></div>
                        </div>
                        <div className="popup-body popup-scorll">
                            <div className="inner-confirmation">
                                <p>An Error Occurred While Processing Your Request</p>
                            </div>
                            <div className="inner-confirmation">
                                <div className="popup-footer">
                                    <button className="btn btn-orange" onClick={() => this.handleConfirmationPopupWrong(false)}>Ok</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="popup-overlay" />
            </div>
            </li>
        )
    }
}

export default FlightSearchDetailsRightGDSRoundTripComponent