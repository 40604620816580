 import jwt from 'jsonwebtoken';
 import pako from 'pako';
const basicHeader = 'Basic 068755101686f650ee6336089a24c5dc97350af0735f4b40c90df2fc9f163bf301df49bafeee90cdcef61a9fc9c26b2091ec1d0960e9856df7a87fe1fc0aa885';
const apiUrl = 'https://api.jaipurflight.com:8080'; //API URL

const apiRailwayUrl = 'https://railwayapi.jaipurflight.com'; //API URL
var axios = require('axios');

var API = {};

const instance = axios.create({
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': basicHeader
    }
});

const sessionInstance = axios.create({

    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'bearer ' + localStorage.getItem('token')
    }
});

const sessionInstanceForProductAccess = () => {
    return axios.create({
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + localStorage.getItem('token')
        }
    })
}

const fileUploadInstance = axios.create({
    headers: {
        'x-device-id': 'stuff',
        'Content-Type': 'multipart/form-data',
        'Authorization': basicHeader
    }
});

const fileDownloadInstance = axios.create({
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'bearer ' + localStorage.getItem('token')
    },
    responseType: 'blob'
});

API.processDownloadResponse = (resp) => {
    let payload = resp;
    let message = resp.data.message ? resp.data.message : 'Processed Ok';
    return { status: 'ok', message: message, ...payload };
};

API.postResponseDownloadSession = (url, credentials) => {
    return fileDownloadInstance.post(apiUrl + url, credentials)
        .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            let fileName = '';
            fileName = 'BookingReport.xlsx';
            link.setAttribute('download', fileName); //or any other extension
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            return API.processDownloadResponse(response);
        })
        .catch((error) => {
            return API.processError(error.response);
        });
};

export const postApiCall = (url, payload,timeOut=0) => {
    return sessionInstance.post(apiUrl + url, payload,{timeout: timeOut})
        .then(response => {
            return response.data;
        })
        .catch((error) => {
            return error;
        });
};

export const bufferDataFun= async (url, payload,timeOut=0)=>{
    try {
        const response = await fetch(apiUrl + url, {
            method: "POST",
            body: JSON.stringify(payload),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'bearer ' + localStorage.getItem('token')
            },
        })
        if (response.ok) {
            const gzippedData = await response.arrayBuffer();
            const unzippedData = pako.inflate(gzippedData, { to: 'string' });
            let finalResponse = JSON.parse(unzippedData)
            return finalResponse
        } else {
            let result = await response.json();
            return result
        }
    } catch (error) {
            return error
    }
           
}

API.processResponse = (resp) => {
    let payload = resp.data.content;
    let message = resp.data.message ? resp.data.message : 'Processed Ok';
    return { status: 'ok', message: message, ...payload };

};

API.processError = (error) => {
    switch (error && error.status) {
        case 400:
            return { status: 'authentication', message: error.data.message, errors: {} };
        case 401:
            return { status: 'Unauthorized', message: error.data.message, errors: {} };
        case 403:
            return { status: 'authentication', message: error.data.message, errors: {} };
        case 422:
            return { status: 'authentication', message: error.data.message, errors: {} };
        case 500:
            return { status: 'server', message: error.data.message, errors: {}, errorMessage: error };
        default:
            return { status: 'network', message: 'Please check your network connection.', errors: {} };
    }
};

API.postResponse = (url, credentials, railwayRoute) => {
    let apiRedirection = apiUrl + url;
    return instance.post(apiRedirection, credentials)
        .then(response => {
            return API.processResponse(response);
        })
        .catch((error) => {
            return API.processError(error.response);
        });
};

API.getResponse = (url, railwayRoute) => {
    let apiRedirection = url === '/get-ip' ? apiRailwayUrl + url : apiUrl + url;
    return instance.get(apiRedirection)
        .then(response => {
            return API.processResponse(response);
        })
        .catch((error) => {
            return API.processError(error.response);
        });
};

API.postResponseSession = (url, credentials, railwayRoute) => {
    let apiRedirection = apiUrl + url;
    let token = localStorage.getItem('token');
    let decoded = jwt.decode(token);
    let tokenExpiry = decoded.exp;
    let currentTimestamp = Math.floor(Date.now() / 1000);
    if (currentTimestamp > tokenExpiry) {
        localStorage.removeItem('token');
        localStorage.removeItem('login');
        localStorage.removeItem('verify-otp');
        return window.location.href = "/login"
    } else {
        return sessionInstance.post(apiRedirection, credentials)
            .then(response => {
                return API.processResponse(response);
            })
            .catch((error) => {
                return API.processError(error.response);
            });
    }


};

API.getResponseSession = (url, railwayRoute) => {
    let apiRedirection = apiUrl + url;
    if (url === '/agent/product-access') { //this API is not getting called with default sessionInstance so we have made a new function to get it called
        return sessionInstanceForProductAccess().get(apiRedirection)
            .then(response => {
                return API.processResponse(response);
            })
            .catch((error) => {
                return API.processError(error.response);
            });
    } else {
        return sessionInstance.get(apiRedirection)
            .then(response => {
                return API.processResponse(response);
            })
            .catch((error) => {
                return API.processError(error.response);
            });
    }
};

API.logout = () => {
    /* reset the headers to not send the token */
    instance.defaults.headers = '';
    return new Promise((res, rej) => {
        setTimeout(() => {
            res('');
        }, 300)
    });
};

API.uploadFile = (url, credentials) => {
    const formData = new FormData();
    formData.append('file', credentials)
    return fileUploadInstance.post(apiUrl + url, formData)
        .then(response => {
            return API.processResponse(response);
        })
        .catch((error) => {
            return API.processError(error.response);
        });
}
export default API;